import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts';
import Projects from '../components/Projects';
import SEO from '../components/SEO';

const ProjectsPage = ({
  data: {
    allStrapiProjects: { nodes: projects },
  },
}) => (
  <Layout>
    <SEO title="Projects" description="All Projects" />
    <section className="projects-page">
      <Projects projects={projects} title="all projects" />
    </section>
  </Layout>
);

export const query = graphql`
  {
    allStrapiProjects {
      nodes {
        id
        title
        url
        description
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        stack {
          id
          title
        }
      }
    }
  }
`;

export default ProjectsPage;
