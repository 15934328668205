import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { FaShareSquare } from 'react-icons/fa';

const Project = ({ description, title, stack, url, image, index }) => (
  <article className="project">
    <Image fluid={image.childImageSharp.fluid} className="project-img" />
    <div className="project-info">
      <span className="project-number">0{index + 1}.</span>
      <h3>{title}</h3>
      <p className="project-desc">{description}</p>
      <div className="project-stack">
        {stack.map(item => (
          <span key={item.id}>{item.title}</span>
        ))}
      </div>
      <div className="project-links">
        <a href={url} target="_blank" rel="noopener noreferrer">
          <FaShareSquare className="project-icon" />
        </a>
      </div>
    </div>
  </article>
);

Project.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  stack: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Project;
